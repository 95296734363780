/* index.css */

html, body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #ffffff; /* Absolute white background */
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-container {
  position: fixed;
  top: 55px;
  left: 0px;
  width: 100%;
  text-align: center;
  display: block;
  background-color: #ffffff; /* Orange background color */
  padding: 10px;
  font-size: 1rem;
  color: #888; /* White text color */
  z-index: 999; /* Ensure it's on top of other content */
}

.notification {
  animation: fade 5s ease-in-out infinite;
  display: block; /* Display the notifications initially */
  background-color: #ffffff; /* Orange background color */
  color: #888; /* White text color */
  padding: 10px;
  text-align: center;
  margin: 0 auto;
  max-width: 80%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes fade {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

.input-text {
  width: 829px;
  height: 113px;
  font-size: 1.5rem;
  border: none;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #f5f5f5; /* Light grey background */
  color: #000; /* Black font color */
}

.input-text:focus {
  border: #000;
}

.submit-button {
  background-color: #f5f5f5; /* Light grey background */
  border: none;
  border-radius: 50%; /* Make it a circle */
  width: 80px; /* Adjust the width and height as needed */
  height: 80px;
  font-size: 0; /* Remove text content */
  cursor: pointer;
  /* Use the actual image URL for your background image */
  background-image: url("static/spotify_logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; /* Ensure the logo fits within the button */
  margin: 0 auto; /* Center the button horizontally */
  display: block; /* Make it a block-level element for centering */
  transition: background-color 0.2s ease; /* Smooth transition for background color change */
}

/* Add the loading animation keyframes */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Keep the green color while the animation is running */
.submit-button.clicked {
  animation: spin 1s linear infinite; /* Apply the spin animation */
  background-color: #4caf50 !important; /* Force green background color */
  pointer-events: none; /* Disable clicks while animation is running */
}

/* Disable the textarea when animation is running */
.input-text.disabled {
  pointer-events: none; /* Disable text entry while animation is running */
}

/* Responsive Media Queries */
@media (max-width: 829px) {
  .input-text {
    width: 100%; /* Full width for screens <= 829px */
  }
}

@media (max-width: 576px) {
  .container {
    width: 95%; /* Adjust for even smaller screens */
  }
}

.powered-by {
  font-size: 1rem; /* Relative font size */
  color: #888; /* Light grey text color */
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

/* Define the vibe-italic class */
.vibe-italic {
  font-style: italic;
  color: #888; /* Set the color for the normal state */
  text-decoration: underline; /* Add underline on hover */
}

/* Define the hover effect for the vibe-italic class */
.vibe-italic:hover {
  font-weight: bold; /* Add underline on hover */
  text-decoration: none;
}

/* Define the visited state for the vibe-italic class */
.vibe-italic:visited {
  color: #ff0000; /* Set the color for the visited state */
  text-decoration: underline;
}
